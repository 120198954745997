export const pageFinder = {
    async asyncData({ req }) {
        if (process.server) {
            return { host: req.headers.host };
        }
    },
    data() {
        return {
            host: '',
            data: {},
            files: [],
            notFound: false,
            meta: {},
            page: {},
            theme: {}
        };
    },
    async beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            if (!vm.host) {
                vm.host = window.location.host; // Get host on client-side.
            }
            await vm.initializeTenant();
        });
    },
    async mounted() {
        if (!this.data || Object.keys(this.data).length === 0) {
            await this.initializeTenant();
        }
    },
    methods: {
        async initializeTenant() {
            if (!this.$store.state.event.eventData) { 
                this.host = this.host || window.location.host;
                const tenant = this.host.split(".")[0];
                this.$store.commit("tenant/UPDATE_TENANT", tenant);
                this.$store.commit("storeFront/UPDATE_TENANT", tenant);
                await this.fetchEventData(tenant);
            }
        },
        async fetchEventData(tenant) {
            try {
                const { data } = await this.$axios.get(`/api/${tenant}/${this.$route.params.slug}`);
                this.data = data.data;
                this.processEventData();
            } catch (error) {
                console.error("Failed to fetch event data:", error);
                this.notFound = true;
            }
        },
        async processEventData() {
            const {
                name, region, vat_rate, not_vat_registered, countries, states, 
                event_products, event_type, expired, files 
            } = this.data;

            this.files = files || []; // Used in themeFinder, for fonts. Needs refactoring.
            this.meta = event_type?.meta || {}; // Used in metaFinder. Needs refactoring.
            this.page = event_type?.page || {}; // Carries info about page customization. Refactor?
            this.theme = event_type?.theme || {};

            if (name) {
                this.$store.commit("event/UPDATE_EVENT_NAME", name);
                this.$store.commit("basket/UPDATE_BASKET_NAME", name);
            }
            if (region) {
                this.$store.commit("event/UPDATE_EVENT_REGION", region);
                this.$store.commit("event/UPDATE_EVENT_CURRENCY", this.getCurrency(region));
            }
            if (vat_rate) {
                this.$store.commit("event/UPDATE_EVENT_VAT_RATE", vat_rate);
            }
            if (not_vat_registered) {
                this.$store.commit("event/UPDATE_NOT_VAT_REGISTERED", not_vat_registered);
            }
            if (countries) {
                this.$store.commit("event/UPDATE_COUNTRIES", countries);
            }
            if (states) {
                this.$store.commit("event/UPDATE_STATES", states);
            }

            this.$store.commit("event/UPDATE_EVENT_TYPE", event_type?.type);

            await this.$store.dispatch("storeFront/assignDataFromAPIToStoreFront", {
                newStoreFrontData: this.data,
                slug: this.$route.params.slug
            });

            this.$i18n.setLocale(this.getDefaultLang());
            this.$store.dispatch('basket/fetchBasketData');
            this.updateFonts();
            this.updateTheme();
        },
        getDefaultLang() {
            if (!sessionStorage.getItem("defaultLangSet")) {
                const lang = this.data?.default_event_lang || "en";
                sessionStorage.setItem("defaultLangSet", "true");
                this.$store.commit("translation/UPDATE_LANG", lang);
                return lang;
            }
            return this.$store.state.translation.lang;
        },
        getCurrency(region) {
            return { UK: '£', EU: '€', US: '$' }[region] || '';
        }
    }
};