
import { metaFinder } from "~/mixins/pages/finder/metaFinder";
import { pageFinder } from "~/mixins/pages/finder/pageFinder";
import { themeFinder } from "~/mixins/pages/finder/themeFinder";

export default {
    auth: false,
    layout: "sub",
    mixins: [metaFinder, pageFinder, themeFinder],
    name: "EventPage",
    transitions: "page",
    data() {
        return {
            loading: false,
            thankYou: false,
            order: {},
            products: {}
        }
    },
    methods: {
        closeModalReally() {
            this.thankYou = false;
        },
        viewBasket() {
            this.$store.commit("basket/SET_BASKET");
        }
    }
}
