/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const themeFinder = {
    computed: {
        fontTheme() {
            let output = "";

            if (this.page && this.page.font_family) {
                output = `
                    body {
                        font-family: ${this.page.font_family};
                    }
                `;
            }

            return output;
        },
        pageTheme() {
            let output = { primary: "#000", secondary: "#fff" };

            if (this.data && this.data.event_type && this.data.event_type.theme) {
                output = this.data.event_type.theme;
            }

            return output;
        },
        rootColours() {
            return `
                :root {
                    --color-primary: ${this.pageTheme.primary};
                    --color-secondary: ${this.pageTheme.secondary};
                    --color-third: ${this.pageTheme.third};
                }
            `;
        }
    },
    methods: {
        setFont() {
            if (this.fontTheme) {
                const styler = document.createElement("style");
                const texter = document.createTextNode(this.fontTheme);

                styler.appendChild(texter);

                document.head.appendChild(styler);
            }
        },
        updateFonts() {
            this.files.forEach(data => {
                const font = new FontFace(data.title, `url(${this.$config.s3Bucket}/${data.location})`, {});

                document.fonts.add(font);

                font.load();
            });

            this.$store.state.storeFront.event.products.forEach(product => {
                product.products.fonts.forEach(data => {
                    if (data.files && data.files.regular) {
                        const font = new FontFace(
                            data.family, `url(${data.files.regular})`, {}
                        );

                        document.fonts.add(font);

                        font.load();
                    } else if (data.fontUrl && data.fontFamily) {
                        const font = new FontFace(
                            data.fontFamily, `url(${data.fontUrl})`, {}
                        );

                        document.fonts.add(font);

                        font.load();
                    }
                });
            });

            if (this.event_type && this.event_type.fonts) {
                const fonts = Object.values(this.event_type.fonts);

                fonts.forEach(data => {
                    if (data && data.family) {
                        const font = new FontFace(
                            data.family, `url(${data.files.regular})`, {}
                        );

                        document.fonts.add(font);

                        font.load();
                    }
                });
            }

            this.setFont();
        },
        updateTheme() {
            if (this.data && this.data.event_type && this.data.event_type.theme) {
                const styler = document.createElement("style");
                const texter = document.createTextNode(this.rootColours);

                styler.appendChild(texter);

                document.head.appendChild(styler);
            }
        }
    }
}